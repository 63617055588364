export class Loader {
    load(scripts) {
        scripts.forEach((script) => {
            new script();
        })
        this.loadComponents();
    }

    loadComponents() {
        import("../components/components.js")
    }

}
