import Swiper, {Pagination, Navigation, Thumbs, Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

export class Slider
{
    constructor()
    {
        document.querySelectorAll('.c-slider--on').forEach(s => {
            const swiper = new Swiper(s, {
                modules: [Pagination, Autoplay],
                slidesPerView: 3,
                spaceBetween: 40,
                autoplay: {
                    delay: 4000,
                },
                // centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    }
                }
            });
        })

        document.querySelectorAll('*[data-slider-home]').forEach(s => {

            const swiper = new Swiper(s, {
                modules: [Pagination, Autoplay],
                autoplay: {
                    delay: 3000,
                },
            });

        });

        document.querySelectorAll('*[data-slider]').forEach(s => {
            const thumbs = s.getAttribute('data-slider-thumbs');
            const thumbsSlider = document.querySelector(thumbs);

            const options = {
                modules: [Navigation],
                spaceBetween: 10,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            };

            if (thumbsSlider) {
                const swiper_thumb = new Swiper(thumbsSlider, {
                    spaceBetween: 32,
                    slidesPerView: 4,
                    loop: true,
                    freeMode: true,
                    watchSlidesProgress: true,
                });

                options.thumbs = {
                    swiper: swiper_thumb
                }

                options.modules.push(Thumbs)
            }

            const swiper = new Swiper(s, options);
        });
    }
}
