export class Archive {

    config(container) {
        this.nbPages = 1
        this.posts_per_page = container.getAttribute('data-archive-postsPerPage');
    }

    constructor() {
        document.querySelectorAll('*[data-archive]').forEach((a) => {

            this.config(a)

            const id = a.getAttribute('data-archive');
            const more = a.querySelector('*[data-archive-more]');
            const moreText = more.innerHTML;
            const endpoint = a.querySelector('*[data-archive-endpoint]');
            const nb_posts = a.getAttribute('data-archive-nbPosts');
            let page = parseInt(a.getAttribute('data-archive-page'));

            const filters = document.querySelectorAll('*[data-filter]');
            let checked_id = [];

            if (filters.length > 0) {
                filters.forEach((filter) => {
                    filter.addEventListener('click', async (e) => {
                        page = 1
                        more.innerHTML = 'Chargement...'
                        checked_id = [];

                        document.querySelectorAll('.c-filter-form__group input:checked').forEach((input) => {
                            checked_id.push(input.value);
                        });

                        const posts = await this.fetchArchiveEquipmentWithFilter(page, id, checked_id.join(","));

                        if (this.nbPages == parseInt(page)) {
                            more.style.display = 'none';
                        } else {
                            more.style.display = 'block';
                            more.innerHTML = moreText
                        }
                        endpoint.innerHTML = ""
                        this.appendSkeletons(endpoint)
                        await this.appendClonedPosts(posts, endpoint)
                        this.removeSkeleton()


                    });
                });
            }

            more.addEventListener('click', async (e) => {

                e.preventDefault();
                this.appendSkeletons(endpoint)
                more.innerHTML = 'Chargement...'
                page++;
                let posts = []

                if (checked_id.length > 0) {

                    posts = await this.fetchArchiveEquipmentWithFilter(page, id, checked_id.join(","))

                    if (posts && posts.length > 0) {
                        await this.appendClonedPosts(posts, endpoint);
                        this.removeSkeleton()
                    }

                    if (this.nbPages == parseInt(page)) {
                        more.style.display = 'none';
                    } else {
                        more.innerHTML = moreText
                    }

                } else {

                    posts = [...await this.fetchArchive(page, id)];

                    if (posts && posts.length > 0) {
                        this.appendPosts(posts, endpoint);
                        this.removeSkeleton()
                    }

                    if ((posts.length < this.posts_per_page) || !posts) {
                        more.style.display = 'none';
                    } else {
                        more.innerHTML = moreText
                    }

                }


            })
        });
    }

    async fetchArchive(page, id) {
        const params = new URLSearchParams();
        params.append('paged', page);

        const url = new URL(window.location.protocol + '//' + window.location.host + window.location.pathname);

        const request = new Request(`${url}?${params.toString()}`, {
            method: 'GET',
        });

        return fetch(request)
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                const parser = new DOMParser();
                const dom = parser.parseFromString(text, "text/html");

                const block = dom.documentElement.querySelector(`*[data-archive=${id}]`);
                const dom_endpoint = block.querySelector('*[data-archive-endpoint]');

                return dom_endpoint?.children;
            });
    }

    async fetchArchiveEquipmentWithFilter(page, id, checked_id) {

        const params = new URLSearchParams();

        params.append('page', page);
        params.append('per_page', this.posts_per_page)

        if (checked_id.length > 0) {
            params.append('aky-equipment-type', checked_id);
        }

        const url = new URL(window.location.protocol + '//' + window.location.host + '/wp-json/wp/v2/aky-equipment');


        const request = new Request(`${url}?${params.toString()}`, {
            method: 'GET',
        });

        return fetch(request)
            .then((response) => {
                this.nbPages = response.headers.get('X-WP-TotalPages')
                return response.json();
            })
            .then((data) => {
                return data
            })
            .catch(error => {
                return error
            });

    }

    appendPosts(posts, endpoint) {
        Array.from(posts).forEach((post) => {
            endpoint.appendChild(post);
        })
    }

    async appendClonedPosts(posts, endpoint) {

        const template = document.querySelector('#archive-el');
        const clones = []

        for (const item of posts) {
            let clone = template.content.cloneNode(true);
            const cloned = await this.clonePost(clone, item)
            clones.push(cloned)
        }

        clones.forEach(clone => {
            endpoint.appendChild(clone)
        })

    }

    async clonePost(clone, item) {

        const thumb = clone.querySelector('.c-product-thumb picture');
        const oldThumbnail = clone.querySelector('.c-product-thumb img');
        const title = clone.querySelector('.c-product-thumb .title');
        const titleOverlay = clone.querySelector('.c-product-overlay .title');
        const text = clone.querySelector('.c-text');
        const cat = clone.querySelector('.c-product-overlay-infos > li:first-child')
        const infos = clone.querySelector('.c-product-overlay-infos > li:nth-child(2)')
        const infos2 = clone.querySelector('.c-product-overlay-infos > li:last-child')

        const urlLocation = window.location.origin;
        const urlMedia = await this.fetchMedia(urlLocation, item.featured_media)
        const taxonomy = await this.fetchTaxonomy(item['aky-equipment-type'][0])

        oldThumbnail.remove()
        const thumbnail = document.createElement('img')
        thumbnail.setAttribute('src', urlMedia)
        thumb.appendChild(thumbnail)

        title.innerHTML = item.title.rendered
        titleOverlay.innerHTML = item.title.rendered
        cat.innerHTML = "Catégorie : "+taxonomy
        if (item.acf.description !== null) text.innerHTML = item.acf.description
        if (item.acf.brand !== "") { infos.innerHTML = "Marque : "+item.acf.brand } else { infos.remove() }
        if (item.acf.supplier !== "") { infos2.innerHTML = "Fournisseur : "+item.acf.supplier } else { infos2.remove() }

        return clone

    }

    async fetchTaxonomy(id) {
        const url = new URL(window.location.protocol + '//' + window.location.host + '/wp-json/wp/v2/aky-equipment-type/' + id);
        return fetch(url)
            .then(response => response.json())
            .then(data => data.name)
            .catch(error => console.error('Error fetching taxonomy data:', error));
        return null;
    }

    async fetchMedia(urLocation, thumbnailId) {
        return fetch(`${urLocation}/wp-json/wp/v2/media/${thumbnailId}`)
            .then(response => response.json())
            .then(mediaData => mediaData.source_url)
            .catch(error => console.error('Error fetching media data:', error));
        return null;
    }

    appendSkeletons(endpoint) {
        const template = document.querySelector('#skeleton');

        for (let i = 0; i < 3; i++) {
            let clone = template.content.cloneNode(true);
            endpoint.appendChild(clone)
        }

    }

    removeSkeleton() {
        document.querySelectorAll('.post--vertical').forEach(s => {
            s.remove()
        })
    }

}
